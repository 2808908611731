import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { type IAccountKeyFile } from '../../interfaces/account'
import type IAccount from '../../interfaces/account'
import api from '../../api'
import { Badge, Button, Col, Container, Form, Row, Spinner, Stack, Table } from 'react-bootstrap'
import './accountEdit.scss'
import { convertISODateToDateString, convertISODateToDatetimeString, getShotDocumentType } from '../../utils/convert'
import DocumentViewModal from '../document/documentView'
import { FaEye, FaFileAlt, FaRegAddressCard, FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa'
import { type IDocument } from '../../interfaces/document'
import { IoMdAddCircle } from 'react-icons/io'
import KeyfileModal from '../keyfileUpload/keyfileUpload'
import { toast } from 'react-toastify'
import { DOCUMENT_STATUS } from '../../interfaces/dictionary'

const AccountEdit = () => {
  const [data, setData] = useState<IAccount>({} satisfies IAccount)
  const [isChecked, setIsChecked] = useState(false)
  const [showDocumentViewModal, setShowDocumentViewModal] = useState<boolean>(false)
  const { id } = useParams()
  const [document, setDocument] = useState<IDocument | null>(null)
  const [showKeyfileModal, setShowKeyfileModal] = useState<boolean>(false)
  const [actionSpinner, setActionSpinner] = useState<string>('')

  const updateData = () => {
    api.get(`accounts/${id}`)
      .then(res => { setData(res.data as IAccount); setIsChecked(res.data!.isActive) }
      )
      .catch(e => {})
  }

  useEffect(() => {
    updateData()
  }, [])

  const handleChangeActive = (val: boolean) => {
    api.put(`accounts/${id}`, { isActive: val })
      .then(res => { setIsChecked(val) }
      )
      .catch(e => {})
  }

  const handleChangeKeyFile = (keyFile: IAccountKeyFile, val: boolean) => {
    api.put(`keyFile/${keyFile.id}`, { isMain: val })
      .then(res => { updateData() }
      )
      .catch(e => {})
  }

  const handleCloseDocumentViewModal = () => {
    setShowDocumentViewModal(false)
  }

  const handleViewDocument = (documentId: number) => {
    api.get(`/document/${documentId}`)
      .then(res => {
        setDocument(res.data as IDocument)
        setShowDocumentViewModal(true)
      })
      .catch(e => {})
  }

  const handleViewReceipt = (receiptId: number) => {
    api.get(`/receipt/${receiptId}`)
      .then(res => {
        const doc = res.data as IDocument
        doc.type = 'J1499102'
        setDocument(doc)
        setShowDocumentViewModal(true)
      })
      .catch(e => {
        console.log(e)
      })
  }

  const handleUpdateData = () => {
    setActionSpinner('updateData')
    api.post(`accounts/${id}/updateData`)
      .then(res => { updateData() })
      .catch(e => {})
      .finally(() => { setActionSpinner('') })
  }

  const handleCloseKeyfileModal = (needReloadData: boolean) => {
    if (needReloadData) {
      updateData()
    }
    setShowKeyfileModal(false)
  }

  const getKeyStyle = (key: IAccountKeyFile): string => {
    if ((new Date(key.certEndTime)).getTime() <= (new Date()).getTime()) {
      return 'bg-danger-subtle'
    } else if (key.isMain ?? false) {
      return 'bg-success-subtle'
    }
    return 'bg-warning-subtle'
  }

  /* global chrome */
  const loginToCabinet = (key: IAccountKeyFile) => {
    if (chrome.runtime === undefined) {
      toast.error(<div>Необхідно встановити розширення:
            <a href='https://chromewebstore.google.com/detail/startax/aoiiaeegkeeonghhoefpckcjcgbhmibf' download>startax.crx</a>
        </div>, {
        position: 'bottom-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: false,
        progress: undefined
      })
    }

    api.get(`/keyFile/${key.id}/base64`)
      .then(res => {
        const signFileData = res.data
        chrome.runtime.sendMessage(
          'aoiiaeegkeeonghhoefpckcjcgbhmibf',
          {
            accountName: data.name,
            signFileName: key.name,
            signFileData,
            signFilePass: key.password,
            signFileACSK: key.issuer
          },
          (response) => {
            if (response !== 'OK') {
              toast.error(`Error: ${response}`, {
                position: 'bottom-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined
              })
            }
          }
        )
      })
      .catch(e => {
        console.log(e)
      })
  }

  return (
      <Container className="pageContainer accountEdit">
          <Row>
              <Stack direction="horizontal">
                  <Form.Group className="mb-3">
                      <Form.Label className="account-name"><h3>{data?.name}</h3></Form.Label>
                  </Form.Group>
                  <div className="p-2 ms-auto">
                      <Button className="add-button" onClick={(e) => { handleUpdateData() }}>
                          {actionSpinner === 'updateData' && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />} Оновити данні з ДПС
                      </Button>
                  </div>
              </Stack>
          </Row>
          <Row>
              <Col>
                  <Form.Group className="form-group mb-3">
                      <Form.Label>ІПН</Form.Label>
                      <Form.Control readOnly={true} value={data?.ipnCode}></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group mb-3">
                      <Form.Label>Телефон</Form.Label>
                      <Form.Control readOnly={true} value={data?.phone}></Form.Control>
                  </Form.Group>
                  <Form.Group className="form-group mb-3">
                      <Form.Label>Адреса</Form.Label>
                      <div className="form-control address">{data?.address}</div>
                  </Form.Group>
                  <Form.Group className="form-group mb-3">
                      <Form.Check
                          type={'switch'}
                          label={'Активний'}
                          checked={isChecked}
                          onChange={(e) => { handleChangeActive(e.currentTarget.checked) }}
                      ></Form.Check>
                  </Form.Group>
              </Col>
              <Col>
                  <Form.Group className="form-group mb-3">
                      <Form.Label>ДПІ</Form.Label>
                      <div className="form-control address">{data?.taxService?.code} {data?.taxService?.name}</div>
                  </Form.Group>
                  <Row>
                      <Col>
                          <Form.Group className="form-group mb-3">
                              <Form.Label>Дата взяття на облік</Form.Label>
                              <Form.Control readOnly={true} value={data?.stiRegDate}></Form.Control>
                          </Form.Group>
                      </Col>
                      <Col>
                          <Form.Group className="form-group mb-3">
                              <Form.Label>Дата зняття з обліку</Form.Label>
                              <Form.Control readOnly={true} value={data?.stiDeregDate}></Form.Control>
                          </Form.Group>
                      </Col>
                  </Row>
                  <Row>
                      <Col>
                          <Form.Group className="form-group mb-3">
                              <Form.Label>Група</Form.Label>
                              <Form.Control readOnly={true} value={data?.taxGroup}></Form.Control>
                          </Form.Group>
                      </Col>
                      <Col>
                          <Form.Group className="form-group mb-3">
                              <Form.Label>Ставка податку</Form.Label>
                              <Form.Control readOnly={true} value={data?.taxRate}></Form.Control>
                          </Form.Group>
                      </Col>
                  </Row>
              </Col>
              <Col>
                      <Form.Label>Види діяльності  <Badge bg="success" pill={true}>{data?.activityTypes?.length}</Badge></Form.Label>
                      <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                          <Table>
                              <thead style={{ position: 'sticky', top: '0' }}>
                              <tr>
                                  <th>Код</th>
                                  <th>Назва</th>
                              </tr>
                              </thead>
                              <tbody>
                              {data?.activityTypes?.map(item => {
                                return (
                                      <tr key={item.id}>
                                          <td>{item.code}</td>
                                          <td>{item.name}</td>
                                      </tr>
                                )
                              })}
                              </tbody>
                          </Table>
                      </div>
              </Col>
          </Row>
          <Row className="mt-4">
              <Col>
                  <Form.Group>
                      <Form.Label>Документи <Badge bg="success" pill={true}>{data?.documents?.length}</Badge>
                      </Form.Label>
                  </Form.Group>
                  <Table striped hover >
                      <thead>
                      <tr>
                          <th>Дата створення</th>
                          <th>Форма</th>
                          <th>Статус</th>
                          <th>Період</th>
                          <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {data?.documents?.map(item => {
                        return (
                              <tr key={item.id}>
                                  <td>{convertISODateToDatetimeString(item.createdAt)}</td>
                                  <td>
                                      <span>{item.type} - </span>
                                      {
                                          {
                                            F01033: 'Декларація',
                                            F01032: 'Декларація - військовий стан',
                                            F13918: '5-ПРРО',
                                            F13021: 'Витяг з реєстру',
                                            F01020: 'Застосування спрощенної системи',
                                            F13166: '1-ПРРО',
                                            F13120: '20-ОПП',
                                            F13911: 'ЕЦП',
                                            F13002: 'Стан розрахунків'

                                          }[getShotDocumentType(item.type)]
                                      }
                                  </td>
                                  <td>{DOCUMENT_STATUS[item.status]}</td>
                                  <td>{item.reportPeriod.name} {item.reportYear}</td>
                                  <td>
                                      <div className="d-flex flex-row" style={{ width: '70px' }}>
                                          <Col className="me-1" xxl={4} xl={4} lg={4}>
                                              <a href={process.env.REACT_APP_API_URL + `/document/${item.id}/pdf`}
                                                 hidden={item.registrationNumber == null}>
                                                  <FaRegFilePdf size={16} className="text-primary"/>
                                              </a>
                                          </Col>
                                          <Col className="me-2" xxl={4} xl={4} lg={4}>
                                              <FaEye style={{ color: 'grey' }} onClick={() => {
                                                handleViewDocument(item.id ?? 0)
                                              }} size={18}/>
                                              <DocumentViewModal show={showDocumentViewModal} doc={document!}
                                                                 handleClose={handleCloseDocumentViewModal}/>
                                          </Col>
                                          <Col>
                                              {item?.receipts?.[0]?.id !== undefined &&
                                                  <FaFileAlt size={16} onClick={() => {
                                                    handleViewReceipt(item?.receipts[0].id ?? 0)
                                                  }}
                                                             className={((item?.receipts?.[0]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                                              }
                                          </Col>
                                          <Col>
                                              {item?.receipts?.[1]?.id !== undefined &&
                                                  <FaFileAlt size={16} onClick={() => {
                                                    handleViewReceipt(item?.receipts[1].id ?? 0)
                                                  }}
                                                             className={((item?.receipts?.[1]?.status >= 0) ? 'receipt-success' : 'receipt-error')}/>
                                              }
                                          </Col>
                                      </div>
                                  </td>
                              </tr>
                        )
                      })}
                      </tbody>
                  </Table>
              </Col>
              <Col>

                  <Form.Group>
                      <div className="d-flex justify-content-between">
                          <Form.Label>
                              <div className="d-flex">
                              <IoMdAddCircle size={20} className="text-success d-flex flex-row-reverse" onClick={(e) => { setShowKeyfileModal(true) }}/>
                              <KeyfileModal show={showKeyfileModal} handleClose={handleCloseKeyfileModal}/>
                              Ключі
                              </div>
                          </Form.Label>
                          <Form.Label>
                              Основний ключ в ДПС
                              <Badge bg="success" pill={true}>{data?.activePublicKeys?.length}</Badge>
                          </Form.Label>
                      </div>
                  </Form.Group>
                      <Table striped hover>
                          <thead>
                          <tr>
                              <th>АЦСК</th>
                              <th>Ім&apos;я файлу</th>
                              <th>Дійсний з</th>
                              <th>Дійсний до</th>
                              <th>Пароль</th>
                              <th>Основний</th>
                              <th>ДПС</th>
                          </tr>
                          </thead>
                          <tbody>
                          {data?.keyFiles?.map(item => {
                            const keyStyle = getKeyStyle(item)
                            return (
                                <tr key={item.id}>
                                    <td className={keyStyle}>{item.issuer}</td>
                                    <td className={keyStyle}>
                                        <a href={process.env.REACT_APP_API_URL + `/keyFile/${item.id}/download`}
                                           className="d-flex">
                                            <FaRegFileExcel size={14}
                                                            className="text-success d-flex flex-row-reverse pt-1"/>
                                            {item.name}
                                        </a>
                                    </td>
                                    <td className={keyStyle}>{convertISODateToDateString(item.certBeginTime)}</td>
                                    <td className={keyStyle}>{convertISODateToDateString(item.certEndTime)}</td>
                                    <td className={keyStyle}>{item.password}</td>
                                    <td className={keyStyle}><Form.Check checked={item.isSign ?? false}
                                                                         disabled={true}/></td>
                                    <td className={keyStyle}>
                                        <div className="d-flex">
                                            <Form.Check defaultChecked={item.isMain ?? false}
                                                        onChange={(e) => { handleChangeKeyFile(item, e.currentTarget.checked) }}/>

                                            {
                                                ((new Date(item.certEndTime)).getTime() > (new Date()).getTime()) &&
                                                <FaRegAddressCard className="mx-1" style={{ color: '#233565' }} onClick={() => {
                                                  loginToCabinet(item)
                                                }} size={18}/>
                                            }
                                        </div>
                                    </td>

                                </tr>
                            )
                          })}
                          </tbody>
                      </Table>
              </Col>
          </Row>
      </Container>
  )
}

export default AccountEdit
